import { useSessionState } from '@/lib/hooks/use-session-state';
import { Logo } from './svg/Logo';

// home link that is dynamic between routes.
export const HomeLink = ({ hideLogo }: { hideLogo?: boolean }) => {
  const { $session } = useSessionState();

  return (
    <a
      href={$session?.access_token ? '/dashboard' : '/'}
      className={`${
        hideLogo ? 'flex md:hidden' : 'flex'
      } place-items-center gap-2 text-xl md:text-2xl hover:bg-green-100 dark:hover:bg-green-900 px-2 py-1 rounded`}
    >
      <Logo
        height={30}
        width={30}
        className="fill-accent-foreground transition-all group-hover:scale-110"
      />
      <span className="hidden md:inline-block font-mono tracking-tight">Spider</span>
    </a>
  );
};
